import axios from 'axios';
import { message } from 'antd';

import localStorage from '../../../utils/localStorage';

export async function getChatList() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/chat/get?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function createConversation() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `/api/chat/create?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {}
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function setNewMessage(data) {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `/api/chat/insertConversation?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function getConversationList(ConversationId) {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `/api/chat/getConversationById?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { ConversationId }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
      return [];
    });
}

export async function getUsersList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/user/get?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function _getAttachmentList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/troublereport/get?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function deleteConversationId(Id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/chat/delete?SiteId=${siteId}&Id=${Id}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function enableDisableDebugMode(ConversationId, DebugMode) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/chat/debug?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      ConversationId,
      DebugMode
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function clearChatForConversation(Id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/chat/clearChat?SiteId=${siteId}&Id=${Id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function OnFeedbackChange(Feedback, MessageId) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/chat/updateFeedback?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      Feedback,
      MessageId
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function _saveTroubleReport(data, Id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let headersPayload = {
    url: `/api/troublereport/${Id ? 'update' : 'create'}?SiteId=${siteId}${Id ? `&Id=${Id}` : ''}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data
  };
  return axios(headersPayload)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getVisualizationData(Id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let headersPayload = {
    url: `/api/chat/getQueryById?Id=${Id}&SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(headersPayload)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getEditObject(Id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let headersPayload = {
    url: `/api/troublereport/get?Id=${Id}&SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(headersPayload)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
