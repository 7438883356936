import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../selectors/language';
import { getUserProfile } from '../../selectors/layout';
import { ChatButton, Form, StyledTextareaAutosize } from './CSS/styles';
import {
  clearChatForConversation,
  createConversation,
  enableDisableDebugMode
} from './Utils/ApICalls';
import { RightOutlined, SelectOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
// import {constant } from '../Settings/Constants';
import translation from '../AMM/Utils/translation';
import { constant } from './constants';

class ChatInput extends React.Component {
  timeTicket = null;
  requestCheck = true;

  constructor(props) {
    super(props);
    this.state = {
      message: '',
      translationCache: props.translationCache || {}
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let { message } = this.state;
    let { chatId } = this.props;

    if (message.trim() === '') return;

    if (chatId) {
      this.props.onNewUserMessage(chatId, message);
    } else {
      let saveResponse = await createConversation();
      if (saveResponse && saveResponse.Id) {
        this.props.onNewChatCreated(saveResponse.Id, message);
      }
    }
    this.setState({ message: '' });
  };

  setDebugMode = async (e, Operation) => {
    e.preventDefault();
    let { chatId, messages } = this.props;
    let debugResponse = {};
    let getLastRecord = messages && Array.isArray(messages) ? messages[messages.length - 1] : [];
    let setNextDebugValue =
      getLastRecord && getLastRecord.DebugStatus && getLastRecord.DebugStatus === 'ENABLED'
        ? 'DISABLED'
        : 'ENABLED';
    if (Operation === 'DebugMode') {
      debugResponse = await enableDisableDebugMode(chatId, setNextDebugValue);
    } else if (Operation === 'ClearMessages') {
      debugResponse = await clearChatForConversation(chatId);
    }

    if (debugResponse && debugResponse.message) {
      this.props.getConversationList(chatId);
    }
  };

  render() {
    let { messages } = this.props;
    let { message } = this.state;
    let getLastRecord = messages && Array.isArray(messages) ? messages[messages.length - 1] : [];
    let setNextDebugValue =
      getLastRecord && getLastRecord.DebugStatus && getLastRecord.DebugStatus === 'DISABLED'
        ? 'ENABLED'
        : 'DISABLED';
    let items = [
      {
        key: '1',
        label: (
          <div onClick={(e) => this.setDebugMode(e, 'DebugMode')}>
            {`${
              setNextDebugValue === translation(this.props.translationCache, constant.DISABLED)
                ? translation(this.props.translationCache, constant.Disable_Debug_Mode)
                : translation(this.props.translationCache, constant.Enable_Debug_Mode)
            }`}
          </div>
        )
      },
      {
        key: '2',
        label: (
          <div onClick={(e) => this.setDebugMode(e, 'ClearMessages')}>
            {translation(this.props.translationCache, constant.Clear_Messages)}
          </div>
        )
      }
    ];
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <StyledTextareaAutosize
            value={message}
            onChange={(e) => this.setState({ message: e.target.value })}
            placeholder={translation(this.props.translationCache, constant.Type_A_Message)}
            maxRows={10}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                this.handleSubmit(e);
              }
            }}
          />
          <ChatButton style={{ marginRight: '6px' }}>
            <Dropdown menu={{ items }} placement="top" arrow>
              <SelectOutlined />
            </Dropdown>
          </ChatButton>
          <ChatButton type="submit">
            <RightOutlined />
          </ChatButton>
        </Form>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ChatInput);
