import React from 'react';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Tooltip, Dropdown, Menu } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { CommentOutlined, CopyOutlined, LogoutOutlined, GlobalOutlined } from '@ant-design/icons';
import { getTranslationsCache } from '../../../modules/translation/translation.duck';
import { getTranslationCache } from '../../../selectors/language';
import { getUserProfile } from '../../../selectors/layout';
import { AppContainer, HeaderContainer } from './../CSS/styles';
// import horizontal_logo from '../../../images/rubus_new/RubusLogo.png';
import localStorage from '../../../utils/localStorage';
import history from '../../../commons/history';
import ChatRoute from '../../../routes/chatRoute';
import { withRouter } from 'react-router-dom';

class ChatApplication extends React.Component {
  timeTicket = null;
  requestCheck = true;

  constructor(props) {
    super(props);
    this.state = {
      languageList: []
    };
  }

  componentDidMount() {
    this.getLanguageList();
  }

  getLanguageList = () => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let changePassword = {
      method: 'GET',
      url: `/api/language/languageList?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(changePassword)
      .then((response) => {
        console.log(response.data, 'ddddddd');
        this.setState({
          languageList: response.data
        });
        this.props.actions.getTranslationsCache(
          response.data && response.data[0] ? response.data[0] : 'English'
        );
      })
      .catch(function () {});
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  setLanguage = (language) => {
    console.log(language, 'llllll');
    this.props.actions.getTranslationsCache(language);
  };

  render() {
    let showChat = false;
    let { languageList } = this.state;
    let { userProfile } = this.props;
    let currentSiteId = localStorage.get('currentSite');
    let logoPath =
      currentSiteId &&
      userProfile &&
      userProfile.belongsTo &&
      userProfile.belongsTo.sites &&
      userProfile.belongsTo.sites[currentSiteId] &&
      userProfile.belongsTo.sites[currentSiteId].site &&
      userProfile.belongsTo.sites[currentSiteId].site['ImageURL']
        ? userProfile.belongsTo.sites[currentSiteId].site['ImageURL']
        : '';

    const language = (
      <HeaderContainer>
        <Menu>
          {languageList &&
            Array.isArray(languageList) &&
            languageList.map((language) => {
              return (
                <Menu.Item
                  key={language}
                  onClick={() => {
                    this.setLanguage(language);
                  }}
                >
                  {' '}
                  {language}
                </Menu.Item>
              );
            })}
        </Menu>
      </HeaderContainer>
    );
    if (JSON.stringify(history.location.pathname).match(`/chat/upload`) !== null) {
      showChat = true;
    }
    return (
      <>
        <HeaderContainer
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: 'white'
          }}
        >
          <div style={{ width: '300px', margin: '6px 12px' }}>
            <img
              src={logoPath !== '' ? `/api/uploads/${logoPath}` : ''}
              alt="LOGO"
              style={{
                width: '100px',
                marginTop: '12px'
              }}
            />
          </div>
          <div
            style={{
              margin: '20px 12px',
              fontSize: '16px',
              cursor: 'pointer',
              display: 'flex'
            }}
          >
            <Dropdown
              overlay={language}
              onClick={(language) => {
                this.setLanguage(language);
              }}
            >
              <div onClick={(e) => e.preventDefault()}>
                <GlobalOutlined
                  style={{
                    fontSize: '17px',
                    color: 'rgb(255, 255, 255)',
                    margin: '6px 14px'
                  }}
                />
              </div>
            </Dropdown>

            <Tooltip
              placement="top"
              title={
                showChat ? this.translation('Open Chat') : this.translation('Upload/View Documents')
              }
            >
              <div
                style={{ marginRight: '20px' }}
                onClick={() => {
                  if (showChat) {
                    history.push('/chat');
                  } else {
                    history.push('/chat/upload');
                  }
                }}
              >
                {showChat ? <CommentOutlined /> : <CopyOutlined />}
              </div>
            </Tooltip>

            <Tooltip placement="top" title={this.translation('Log Out')}>
              <div
                onClick={() => {
                  localStorage.clear();
                  history.push('/login/BPT');
                  window.location.reload();
                }}
              >
                <LogoutOutlined /> {this.translation('Log Out')}
              </div>
            </Tooltip>
          </div>
        </HeaderContainer>

        <AppContainer>
          <ChatRoute />
        </AppContainer>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getTranslationsCache
      },
      dispatch
    )
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChatApplication));
