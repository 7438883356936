import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../../selectors/language';
import { getUserProfile } from '../../../selectors/layout';
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Space,
  Table,
  Tooltip,
  Upload,
  message
} from 'antd';
import '../CSS/style.css';
import {
  _getAttachmentList,
  _saveTroubleReport,
  getEditObject,
  getUsersList
} from '../Utils/ApICalls';
import { DownloadOutlined, EditOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import localStorage from '../../../utils/localStorage';
import TextArea from 'antd/lib/input/TextArea';
import Highlighter from 'react-highlight-words';

class ChatUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
      open: false,
      data: {},
      fileList: []
    };
  }
  componentDidMount() {
    this._getUserList();
    this.getAttachmentList();
  }

  getAttachmentList = async () => {
    let attachments = await _getAttachmentList();
    this.setState({ attachments });
  };

  _getUserList = async () => {
    let userList = await getUsersList();
    this.setState({ userList });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation('Search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  getFormData = (data) => {
    _.cloneDeepWith(data, (value) => {
      return value && value !== undefined && value !== null
        ? {
            ...value,
            ...(value['TroubleTime'] && value['TroubleTime'] !== null
              ? (value['TroubleTime'] = moment.utc(value.TroubleTime, 'YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['ActualStart'] && value['ActualStart'] !== null
              ? (value['ActualStart'] = moment.utc(value.ActualStart, 'YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['ActualEnd'] && value['ActualEnd'] !== null
              ? (value['ActualEnd'] = moment.utc(value.ActualEnd, 'YYYY-MM-DD HH:mm:ss'))
              : null)
          }
        : _.noop();
    });
    this.setState({ data });
  };
  disabledDate = (currentDay) => {
    return currentDay > moment().endOf('day');
  };

  renderForm(columns, data) {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    let { maxLength, disable, placeHolder, widget, key } = columns;

    switch (widget) {
      case 'input':
        return (
          <Input
            value={data && data[key] ? data[key] : ''}
            maxLength={maxLength ? maxLength : 100}
            disabled={disable ? disable : false}
            placeholder={placeHolder ? placeHolder : ''}
            onChange={(e) => this.setState({ data: { ...data, [key]: e.target.value } })}
          />
        );
      case 'datePicker':
        return (
          <DatePicker
            value={
              data && data[key] && data[key] !== null && data[key] !== 'Invalid date'
                ? moment(data[key])
                : undefined
            }
            showNow={false}
            showTime
            allowClear={false}
            format={dateFormat}
            disabledDate={this.disabledDate}
            placeholder={placeHolder ? placeHolder : ''}
            onChange={(e) => {
              data = { ...data, [key]: moment(e).format(dateFormat) };
              if (
                (key === 'ActualStart' || key === 'ActualEnd') &&
                data['ActualStart'] &&
                data['ActualEnd']
              ) {
                let TotalDowntime = moment(data['ActualEnd'], 'YYYY-MM-DD HH:mm').diff(
                  moment(data['ActualStart'], 'YYYY-MM-DD HH:mm'),
                  'minutes'
                );
                data = { ...data, TotalDowntime };
                this.setState({ data });
              } else {
                this.setState({ data });
              }
            }}
          />
        );
      case 'textArea':
        return (
          <TextArea
            value={data && data[key] ? data[key] : ''}
            rows={4}
            placeholder={placeHolder}
            onChange={(e) => this.setState({ data: { ...data, [key]: e.target.value } })}
          />
        );

      default:
        return (
          <Input
            value={data && data[key] ? data[key] : ''}
            maxLength={maxLength ? maxLength : 100}
            disabled={disable ? disable : false}
            placeholder={placeHolder ? placeHolder : ''}
            onChange={(e) => this.setState({ data: { ...data, [key]: e.target.value } })}
          />
        );
    }
  }

  onSave = async (formObject) => {
    let { data } = this.state;
    let checkRows = await this.checkAllMandatoryFields(formObject, data);
    if (checkRows === false) {
      let saveTroubleReport = await _saveTroubleReport(data, data.Id);
      if (saveTroubleReport && saveTroubleReport.message) {
        this.setState({ Id: undefined, open: false, data: {} });
        this.getAttachmentList();
      }
    } else {
      message.error('Please enter mandatory fields');
    }
  };
  checkAllMandatoryFields = (formObject, data) => {
    let checkRow = false;
    let allMandatoryList = formObject
      .filter((e) => e.required === true)
      .map((e) => {
        return e.key;
      });

    if (data && Object.keys(data) && Object.keys(data).length > 0) {
      allMandatoryList.map((fields) => {
        if (data[fields] && data[fields] !== '') {
          return {};
        } else {
          checkRow = true;
        }
        return {};
      });
    } else {
      checkRow = true;
    }
    return checkRow;
  };

  onEdit = async (record) => {
    let editObject = await getEditObject(record.Id);
    if (editObject && editObject[0]) {
      this.setState({
        data: editObject[0],
        open: true
      });
    }
  };

  render() {
    let { attachments, data } = this.state;
    const siteId = localStorage.get('currentSite');
    const columns = [
      {
        title: this.translation('Device Name'),
        dataIndex: 'DeviceName',
        key: 'DeviceName',
        ...this.getColumnSearchProps('DeviceName')
      },
      {
        title: this.translation('Trouble Time'),
        dataIndex: 'TroubleTime',
        key: 'TroubleTime',
        render: (text) => <>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : text}</>
      },
      {
        title: this.translation('Problem'),
        dataIndex: 'Problem',
        key: 'Problem',
        ...this.getColumnSearchProps('Problem')
      },
      {
        title: this.translation('Start Time'),
        dataIndex: 'ActualStart',
        key: 'ActualStart',
        render: (text) => <>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : text}</>
      },
      {
        title: this.translation('End TIme'),
        dataIndex: 'ActualEnd',
        key: 'ActualEnd',
        render: (text) => <>{text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : text}</>
      },
      {
        title: this.translation('Total Downtime(mins)'),
        dataIndex: 'TotalDowntime',
        key: 'TotalDowntime',
        ...this.getColumnSearchProps('TotalDowntime')
      },
      {
        title: this.translation('Power Generation Loss'),
        dataIndex: 'PowerGenerationLoss',
        key: 'PowerGenerationLoss',
        ...this.getColumnSearchProps('PowerGenerationLoss')
      },
      {
        title: this.translation('Sales Loss'),
        dataIndex: 'SalesLoss',
        key: 'SalesLoss',
        ...this.getColumnSearchProps('SalesLoss')
      },
      {
        title: this.translation('Prepared By'),
        dataIndex: 'PreparedBy',
        key: 'PreparedBy',
        ...this.getColumnSearchProps('PreparedBy')
      },
      {
        title: this.translation('Vector DB Status'),
        dataIndex: 'VectorDBStatus',
        key: 'VectorDBStatus',
        ...this.getColumnSearchProps('VectorDBStatus')
      },
      {
        title: this.translation('Actions'),
        dataIndex: 'Actions',
        key: 'Actions',
        render: (text, record) => (
          <>
            <Tooltip placement="top" key="download" title={this.translation('Download')}>
              <>
                <a
                  href={`https://bpt-bmgpt.bmecomosolution.com/api/troublereport/download?Id=${record.Id}&SiteId=${siteId}`}
                >
                  <DownloadOutlined style={{ color: '#163046', fontSize: '22px' }} />
                </a>
              </>
            </Tooltip>
            <Tooltip placement="top" key="Edit" title={this.translation('Edit')}>
              <>
                <EditOutlined
                  onClick={() => this.onEdit(record)}
                  style={{
                    color: '#163046',
                    fontSize: '22px',
                    cursor: 'pointer',
                    marginLeft: '10px'
                  }}
                />
              </>
            </Tooltip>
          </>
        )
      }
    ];
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const props = {
      action:
        data && (!data.VectorDBStatus || data.VectorDBStatus !== 'Success')
          ? data && data.VectorDBStatus && data.VectorDBStatus === 'Error'
            ? `/api/troublereport/retryVectorDB?SiteId=${siteId}&Id=${data.Id}`
            : `/api/troublereport/upload?SiteId=${siteId}`
          : '',
      headers: {
        Authorization: Authorization
      },
      onChange: (info) => {
        if (info.file.status === 'done') {
          message.success(info.file.response.message);
          if (this.state.data && this.state.data.Id) {
            this.onEdit(this.state.data);
          } else {
            let { data, id } = info.file.response;
            this.getFormData(data);
            this.setState({ Id: id });
          }
        } else if (info.file.status === 'error') {
          message.error(info.file.response.message);
        }
        this.setState({ fileList: info.fileList });
      }
    };

    const onClose = () => {
      this.getAttachmentList();
      this.setState({ open: false, data: {}, fileList: [] });
    };

    let formObject = [
      {
        title: this.translation('Device Name'),
        key: 'DeviceName',
        widget: 'input',
        placeHolder: this.translation('Input Device Name'),
        required: true
      },
      {
        title: this.translation('Device Description'),
        key: 'DeviceDescription',
        widget: 'input',
        placeHolder: this.translation('Input Device Description'),
        required: true
      },
      {
        title: this.translation('Trouble Time'),
        key: 'TroubleTime',
        widget: 'datePicker',
        placeHolder: this.translation('Input Trouble Time'),
        required: true
      },
      {
        title: this.translation('Start Time'),
        key: 'ActualStart',
        widget: 'datePicker',
        placeHolder: this.translation('Input Start Time'),
        required: true
      },
      {
        title: this.translation('End TIme'),
        key: 'ActualEnd',
        widget: 'datePicker',
        placeHolder: this.translation('Input End Time'),
        required: true
      },
      {
        title: this.translation('Total Downtime(mins)'),
        key: 'TotalDowntime',
        widget: 'input',
        placeHolder: this.translation('Input Total Downtime'),
        required: true
      },
      {
        title: this.translation('Problem'),
        key: 'Problem',
        widget: 'input',
        placeHolder: this.translation('Input Problem'),
        required: true
      },

      {
        title: this.translation('Summary'),
        key: 'Summary',
        widget: 'textArea',
        placeHolder: this.translation('Input Summary'),
        required: true
      },
      {
        title: this.translation('Cause'),
        key: 'Cause',
        widget: 'textArea',
        placeHolder: this.translation('Input Cause'),
        required: false
      },
      {
        title: this.translation('Remedy'),
        key: 'Remedy',
        widget: 'textArea',
        placeHolder: this.translation('Input Remedy'),
        required: false
      },
      {
        title: this.translation('Actions'),
        key: 'Actions',
        widget: 'textArea',
        placeHolder: this.translation('Input Actions'),
        required: false
      },
      {
        title: this.translation('Power Generation Loss'),
        key: 'PowerGenerationLoss',
        widget: 'input',
        placeHolder: this.translation('Input Power Generation Loss'),
        required: false
      },
      {
        title: this.translation('Sales Loss'),
        key: 'SalesLoss',
        widget: 'input',
        placeHolder: this.translation('Input Sales Loss'),
        required: false
      },
      {
        title: this.translation('Prepared By'),
        key: 'PreparedBy',
        widget: 'input',
        placeHolder: this.translation('Input Prepared By'),
        required: false
      }
    ];
    return (
      <>
        <div style={{ margin: '20px' }}>
          <Button typr="primary" onClick={() => this.setState({ open: true })}>
            {this.translation('Add')}
          </Button>

          <Drawer
            title={
              data && data.Id
                ? this.translation('Update Trouble Report')
                : this.translation('Create Trouble Report')
            }
            placement={'bottom'}
            width={500}
            height={window.innerHeight - 135}
            onClose={onClose}
            open={this.state.open}
            extra={
              <Space>
                <Button onClick={onClose}>{this.translation('Cancel')}</Button>
                <Button type="primary" onClick={() => this.onSave(formObject)}>
                  {this.translation(data && data.Id ? 'Update' : 'Save')}
                </Button>
              </Space>
            }
          >
            {data && data.Id ? (
              <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                {data.VectorDBStatus}{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {this.translation('Vector Database Upload')}:{' '}
                </span>
              </div>
            ) : null}
            {data && (!data.VectorDBStatus || data.VectorDBStatus !== 'Success') ? (
              <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Upload {...props} fileList={this.state.fileList}>
                  <Button size="small" icon={<UploadOutlined />}>
                    {data && data.VectorDBStatus && data.VectorDBStatus === 'Error'
                      ? this.translation('Retry PDF Upload')
                      : this.translation('Upload PDF Document')}
                  </Button>
                </Upload>
              </div>
            ) : null}

            <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              autoComplete="off"
            >
              {formObject && Array.isArray(formObject)
                ? formObject.map((column, index) => {
                    return (
                      <div key={index}>
                        <Form.Item required={column.required || false} label={column.title}>
                          {this.renderForm(column, data)}
                        </Form.Item>
                      </div>
                    );
                  })
                : null}
            </Form>
          </Drawer>
          <Table
            className="TableWidget"
            style={{ marginTop: '20px' }}
            columns={columns ? columns : []}
            dataSource={attachments ? attachments : []}
            scroll={{ x: 1000, y: 1000 }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ChatUpload);
