import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';

import { StyledHomeRouter } from './styles';
import {
  // DefaultLandingPage,
  // XMLConfiguration,
  // MaximoEdgeConfiguration,
  // FileUploadAndDownload,
  // AssetMapping,
  // MaximoNotification,
  SettingsPage
  // GenericPageNotFound,
  // MasterView,
  // AssetDeviceList,
  // Device,
  // DeviceType,
  // ApplicationSettings,
  // AssetHierarchyView,
  // Trends,
  // ViewTrends,
  // HMI,
  // HmiConfiguration,
  // DashboardConfiguration,
  // ViewDashboard,
  // EditDashboard,
  // ViewDashboardGroup,
  // Report,
  // ReportConfiguration,
  // ReportTypeConfiguration,
  // DataWatch,
  // ISOReports,
  // ELogBookConfiguration,
  // ELogBookView,
  // DownTimeLogs,
  // RuleEngine,
  // RulesList,
  // RulesFeedback,
  // RuleEngineRootCause,
  // EquipmentMasters,
  // EMM,
  // AMM,
  // Analytics,
  // AnalyticsDetails,
  // QueryBuilder,
  // SmartControl,
  // SmartControlTags,
  // MoistureSensor,
  // QrDetails,
  // QrList,
  // CraneStatus,
  // FormGenerator,
  // FormGeneratorLink,
  // AllModulesList,
  // EquipmentOverview,
  // XMLConfigurationLists
} from '../pages';
// import MapCoordinates from '../pages/Dashboard/ChartComponents/Widgets/Unused/GeoMap/MapWithClusterExample';
// import SiteOverview from '../pages/Modules/Common/DefaultLandingPage/CompleteTagStatus';
// import RealTimeAssetTracking from '../pages/Dashboard/ChartComponents/Widgets/Unused/GeoMap/MapWithSidePanel';
// import TurbineOverview from '../pages/Modules/Common/DefaultLandingPage/TurbineHomePage';
// import TrendsAnalysis from '../pages/TrendAnalysis';

export class HomePageRouter extends Component {
  dynamicRouteRender = (routes, index) => {
    return (
      routes &&
      Array.isArray(routes) &&
      routes.map((route) => {
        return <Route key={index} {...route} />;
      })
    );
  };

  render() {
    document.title = 'Rubus-Industrial IoT Platform';

    let routes = [
      // { path: '/rubus/LandingPage', component: DefaultLandingPage },
      // { path: '/rubus/EquipmentOverview', component: EquipmentOverview },
      { path: '/rubus/settingsPage', component: SettingsPage }
      // { path: '/rubus/CentralDashboard', component: AllModulesList },
      // { path: '/rubus/CraneStatus', component: CraneStatus },
      // { path: '/rubus/MaximoXMLConfigurationLists', component: XMLConfigurationLists },
      // { path: '/rubus/MaximoXMLConfiguration', component: XMLConfiguration },
      // { path: '/rubus/MaximoEdgeConfiguration', component: MaximoEdgeConfiguration },
      // { path: '/rubus/FileUploadAndDownload', component: FileUploadAndDownload },
      // { path: '/rubus/MaximoNotification', component: MaximoNotification },
      // { path: '/rubus/AssetMapping', component: AssetMapping },
      // { path: '/rubus/master/:mastername', component: MasterView },
      // { path: '/rubus/assetConfiguration', component: AssetDeviceList },
      // { path: '/rubus/assetConfiguration/:location/:device', component: AssetDeviceList },
      // { path: '/rubus/equipment', component: Device },
      // { path: '/rubus/equipment-type', component: DeviceType },
      // { path: '/rubus/configurationSettings', component: ApplicationSettings },
      // { path: '/rubus/AssetHierarchyView', component: AssetHierarchyView },
      // { path: '/rubus/Trends', component: Trends, exact: true },
      // { path: '/rubus/Trends/:module', component: Trends, exact: true },
      // { path: '/rubus/ViewTrends/:module/:id', component: ViewTrends, exact: true },
      // { path: '/rubus/TrendsAnalysis', component: TrendsAnalysis, exact: true },
      // { path: '/rubus/smartcontrol/:formName', component: SmartControl, exact: true },
      // { path: '/rubus/smartcontrol', component: SmartControl, exact: true },
      // { path: '/rubus/SmartControlTag', component: SmartControlTags, exact: true },
      // { path: '/rubus/QrList', component: QrList, exact: true },
      // { path: '/rubus/QrDetails/:id', component: QrDetails, exact: true },
      // { path: '/rubus/hmiConfiguration', component: HmiConfiguration },
      // { path: '/rubus/HMI/:type/:hmiName', component: HMI, exact: true },
      // { path: '/rubus/HMI/:type/:hmiName/:dynamicName', component: HMI, exact: true },
      // { path: '/rubus/dashboard', component: DashboardConfiguration, exact: true },
      // { path: '/rubus/dashboard/:name', component: ViewDashboard },
      // { path: '/rubus/dashboard/:name/:deviceCode', component: ViewDashboard },
      // { path: '/rubus/editDashboard', component: EditDashboard },
      // { path: '/rubus/dashboards/:name', component: ViewDashboardGroup },
      // { path: '/rubus/reportConfiguration', component: ReportConfiguration },
      // { path: '/rubus/report/:reportType/:report', component: Report },
      // { path: '/rubus/ReportTypeConfiguration', component: ReportTypeConfiguration },
      // { path: '/rubus/DataWatch/:datawatch/:craneName/:groupname', component: DataWatch },
      // { path: '/rubus/DataWatch/:datawatch/:craneName', component: DataWatch },
      // { path: '/rubus/DataWatch/:datawatch', component: DataWatch },
      // { path: '/rubus/ISOReports', component: ISOReports },
      // { path: '/rubus/FFT', component: FormGenerator },
      // { path: '/rubus/FFTLink', component: FormGeneratorLink },
      // { path: '/rubus/Configuration/ELogBook', component: ELogBookConfiguration },
      // { path: '/rubus/LogBook/:name', component: ELogBookView },
      // { path: '/rubus/DownTime', component: DownTimeLogs },
      // { path: '/rubus/RuleEngine', component: RuleEngine, exact: true },
      // { path: '/rubus/RulesList', component: RulesList, exact: true },
      // { path: '/rubus/RuleEngine/:id', component: RuleEngine, exact: true },
      // { path: '/rubus/RulesFeedback', component: RulesFeedback, exact: true },
      // { path: '/rubus/Rule/RootCause', component: RuleEngineRootCause, exact: true },
      // { path: '/rubus/EMM', component: EMM },
      // { path: '/rubus/EquipmentMaster', component: EquipmentMasters },
      // { path: '/rubus/AMM', component: AMM },
      // { path: '/rubus/Analytics', component: Analytics },
      // { path: '/rubus/AnalyticsDetails/:Id', component: AnalyticsDetails },
      // { path: '/rubus/QueryBuilder', component: QueryBuilder },
      // { path: `/rubus/form/:deviceId/:formName`, component: MoistureSensor },
      // { path: `/rubus/map/getCoordinates`, component: MapCoordinates },
      // { path: `/rubus/map/RealTimeAssetTracking`, component: RealTimeAssetTracking },
      // { path: `/rubus/map/TurbineOverview`, component: TurbineOverview },
      // { path: `/rubus/SiteOverview`, component: SiteOverview },
      // { path: '*', component: GenericPageNotFound }
    ];

    return (
      <StyledHomeRouter>
        <Switch>{this.dynamicRouteRender(routes)}</Switch>
      </StyledHomeRouter>
    );
  }
}
export default withRouter(HomePageRouter);
