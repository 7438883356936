import React from 'react';
import axios from 'axios';
import history from '../../../commons/history';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Form, message, Input, Button } from 'antd';

import { HeaderLogo, Welcome } from './styles';
import rubusLogo from '../../../images/rubus_new/BMGPT.png';

import { getTranslationCache } from '../../../selectors/language';
import { constant } from '../Constants';

const FormItem = Form.Item;

class ChangeFirstPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      confirmDirty: false,
      translationCache: props.translationCache || []
    };
  }
  changeFirstPwd = React.createRef();

  handleSubmit = (e) => {
    e.preventDefault();
    let path = history.location.pathname.split('/changeFirstPassword/');
    this.changeFirstPwd.current
      .validateFields()
      .then(async (values) => {
        this._changePasswordAPI({
          ...values,
          identifier: path[1]
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  _changePasswordAPI = (data) => {
    const changePassword = {
      method: 'POST',
      url: '/api/auth/changeFirstPassword ',
      headers: {
        // Authorization: `Bearer ${accessToken}`
      },
      data
    };
    axios(changePassword)
      .then((response) => {
        message.success(response.data.message);
        history.push('/login');
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { translationCache } = this.state;

    return (
      <section style={{ width: '100%', textAlign: 'center' }}>
        <HeaderLogo>
          <img src={rubusLogo} alt="logo" style={{ height: '42px' }} />
        </HeaderLogo>
        <Welcome>{this.translation('Change Password')}</Welcome>

        <Form ref={this.changeFirstPwd}>
          <FormItem
            name="siteName"
            rules={[{ required: true, message: this.translation('SiteName') }]}
          >
            <Input style={{ width: '380px' }} placeholder={this.translation('SiteName')} />
          </FormItem>
          <FormItem
            name="oldPassword"
            rules={[{ required: true, message: this.translation('Current Password') }]}
          >
            <Input.Password
              style={{ width: '380px' }}
              placeholder={this.translation('Current Password')}
            />
          </FormItem>
          <div>
            {this.translation('You must provide your current password in order to change it.')}
          </div>
          <FormItem
            name="password"
            rules={[{ required: true, message: this.translation('New Password') }]}
          >
            <Input.Password
              style={{ width: '380px' }}
              placeholder={this.translation('New Password')}
            />
          </FormItem>
          <FormItem
            name="confirmPassword"
            rules={[{ required: true, message: this.translation('Confirm Password') }]}
          >
            <Input.Password
              style={{ width: '380px' }}
              placeholder={this.translation('Password confirmation')}
            />
          </FormItem>
          <FormItem>
            <Button type="primary" onClick={this.handleSubmit}>
              {translationCache && translationCache[`${constant.Save_Password}`]
                ? translationCache[`${constant.Save_Password}`]
                : `${constant.Save_Password}`}
            </Button>
          </FormItem>
        </Form>
      </section>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(ChangeFirstPasswordComponent);
