import React from 'react';
import { Route } from 'react-router';

import Chat from './../pages/ChatApplication';
import ChatUpload from './../pages/ChatApplication/ChatUpload';
import ChatWidgets from './../pages/ChatApplication/ChatWidgets';

export default class ChatRoute extends React.Component {
  render() {
    return (
      <>
        <Route exact path="/chat" component={Chat} />
        <Route exact path="/chat/upload" component={ChatUpload} />
        <Route path="/chat/graph" component={ChatWidgets} />
      </>
    );
  }
}
